.App {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

html, 
body,
#root,
.App {
    height: 100%;
}

li {
  list-style-type: none;
}

@font-face {
  font-family: 'SourceCodePro';
  src: local('SourceCodePro'), url(./font/sourcecodepro-medium-webfont.woff) format('woff');
}

body {
  font-family: 'SourceCodePro';
  color: white;
}

/*
  ##############################################
  GRID
  ##############################################  
*/

.grid {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
}

.grid li {
  overflow: hidden;
  flex: auto;
  min-height: 100%;
  min-width: 150px;
  max-height: 250px;
}

.grid li.wide {
  width: 650px;
}

.grid li.tall {
  width: 650px;
  height: 450px;
}

.grid li.narrow {
  width: 120px;
}
.item {
  /* padding: 8px;
  margin: 8px; */
}

.item:nth-child(5n+1) {
  width: 300px;
}

.item:nth-child(5n+2) {
  width: 200px;
}

.item:nth-child(5n+3) {
  width: 300px;
}

.item:nth-child(5n+4) {
  width: 400px;
  height: 500px;
}

.item:nth-child(5n+5) {
  width: 300px;
}  


/*
img.ml-image {
  max-width: 100%;
}

.item-ml {
  position: relative;
}

.set-origin-btn {
  position: absolute;
  left: 0;
  opacity: 0.7;
}

.set-style-btn {
  position: absolute; 
  right: 0;
  opacity: 0.7;
}
 */
