.header {
    border-bottom: 8px solid black;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background-color: white;
  }
  
  .header a{
    color: black;
  }