.Photos-Container {
    height: 100%;
    max-width: 95vw;
    margin: 16px auto;
    padding: 8px;
  }
  
  .Photos-Container .item {
    position: relative;
    width: 100%;
    display: flex;
  }
  
  .Photos-Container img {
    width: 100%;
  }
  
  .Photos-Container .edit-buttons {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .my-masonry-grid {
     display: -webkit-box; /* Not needed if autoprefixing */
     display: -ms-flexbox; /* Not needed if autoprefixing */
     display: flex;
     margin-left: -8px; /* gutter size offset */
     width: auto;
    }
    .my-masonry-grid_column {
     padding-left: 8px; /* gutter size */
     background-clip: padding-box;
    }
    
    /* Style your items */
    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
     background: grey;
     margin-bottom: 8px;
    }